import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Plot from "react-plotly.js";
import spacetime from "spacetime";
import useFetchNodes from "../../hooks/useFetchNodes";
import useTimer from "../../hooks/useTimer";
import { makeStyles } from "@material-ui/core/styles";
import useFetchData from "../../hooks/useFetchData";
import {
  generateRandomFile,
  fillPathsWithData,
  fillNodesMetricData,
  coalitions,
  nodeMetrics, fillCompareMetricData
} from "../../helpers";
import Navigation from "../Navigation/Navigation";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vw",
    paddingTop: "25%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
}));

const layout = {
  dragmode: "zoom",
  margin: { r: 0, t: 0, b: 100, l: 150 },
  autoexpand: true,
  autosize: true,
  automargin: true,
  showlegend: true,
  xaxis: {
    title: 'Hour',
    autorange: true
  },
  legend: {
    y: 0.5,
    font: {size: 16},
    yref: 'paper'
  },
  yaxis: {
    title: 'Values',
    autorange: true
  },
};

const Network = ({
  showNodes = true,
  showPaths = true,
  nodeMetric = "k",
  maxNumOfPaths = 50,
  useTrend = false,
  onUsageChange = () => {},
  pathPrec,
  viewRange,
  groupCoalitions,
  compareReplies,
}) => {
  const classes = useStyles();
  const [frames, setFrames] = useState([]);
  const [frameId, setFrameId] = useState(0);
  const [day, setDay] = useState(1);
  const [coalition, setCoalition] = useState(0);
  const [week, setWeek] = useState(1);
  const [month, setMonth] = useState({ num: 10, days: 16 });
  const [{ nodes, paths }, isLoading, isError, doFetchPaths] = useFetchNodes(
    pathPrec
  );
  const [
    { data: monthData, isLoading: isMonthLoading },
    doFetchMonth,
  ] = useFetchData(`${month.num}-paths.json`, {});
  const [
    { data: monthMetricsData, isLoading: isMonthMetricsLoading },
    doFetchMonthMetrics,
  ] = useFetchData(`${month.num}-sentiment.json`, {});
  const [
    { data: monthSentimentData, isLoading: isMonthSentimentLoading },
    doFetchUsageMetrics,
  ] = useFetchData(`${month.num}-usage.json`, {});
  const { time, start, pause, reset, isRunning } = useTimer({
    endTime: 1440 / 15,
    initialTime: frameId,
  });

  useEffect(() => {
    doFetchPaths(pathPrec)
  }, [pathPrec])

  useEffect(() => {
    if (
      monthData != null &&
      monthData["1"] != null &&
      monthMetricsData != null &&
      monthMetricsData["1"] != null
    ) {
      let nodesList = nodes.list
      if(coalition !== 0 ) {
        nodesList = nodes.list.filter(party => party.coalition === coalition)
      }
      if(compareReplies != null) {
        nodesList = nodes.list.filter(party => party.name === compareReplies)
      }
      if(groupCoalitions === true) {
        nodesList = coalitions
      }
      if(compareReplies != null) {
        setFrames(
          Array(1440 / 60)
            .fill(0)
            .map((val, id) => [
              ...nodesList.map(
                fillCompareMetricData(
                  viewRange === 'day' ? monthMetricsData[day] : monthMetricsData || [],
                  'po',
                  useTrend,
                  viewRange,
                  week,
                  groupCoalitions,
                  compareReplies,
                  viewRange === 'day' ? monthMetricsData[day] : monthMetricsData || [],
                  viewRange === 'day' ? monthMetricsData[day]: monthMetricsData || []
                )
              )
            ]).flat()
        );
      } else {
        setFrames(
          Array(1440 / 60)
            .fill(0)
            .map((val, id) => [
              ...nodesList.map(
                fillNodesMetricData(
                  viewRange === 'day' ? monthMetricsData[day] : monthMetricsData || [],
                  nodeMetric,
                  useTrend,
                  viewRange,
                  week,
                  groupCoalitions,
                  compareReplies,
                  viewRange === 'day' ? monthMetricsData[day] : monthMetricsData || [],
                  viewRange === 'day' ? monthMetricsData[day]: monthMetricsData || []
                )
              )
            ])
        );
      }
    } else {
      setFrames(
        Array(1440 / 15)
          .fill(0)
          .map((val, id) => [...nodes.list])
      );
    }
  }, [
    isLoading,
    isMonthLoading,
    isMonthMetricsLoading,
    day,
    week,
    coalition,
    showNodes,
    showPaths,
    nodeMetric,
    maxNumOfPaths,
    useTrend,
    viewRange,
    groupCoalitions,
    compareReplies
  ]);

  useEffect(() => {
    if (time === 1440 / 15 && day < month.days) {
      setDay(day + 1);
      start(1);
    }
  }, [time, day]);

  const data = frames[time] || [];
  const handleAnimationChange = (action) => {
    switch (action) {
      case "play":
        start();
        break;
      case "pause":
        pause();
        break;
      default:
        console.log(action);
        break;
    }
  };
  const onMonthChange = (month) => {
    setFrameId(0);
    reset(0);
    setMonth({ num: month.num, days: month.days });
    if(month.days < day) {
      setDay(1);
    }
    doFetchMonth(`${month.num}-paths.json`);
    doFetchMonthMetrics(`${month.num}-sentiment.json`);
    doFetchUsageMetrics(`${month.num}-usage.json`);
  };
  const onHourChange = (hour) => {
    setFrameId(hour);
    reset(hour);
  };
  const onDayChange = (day) => {
    setDay(day);
  };
  const onCoalitionChange = (coalitionId) => {
    setCoalition(coalitionId);
  };
  const onWeekChange = (newWeek) => {
    setWeek(newWeek);
  };
  return (
    <React.Fragment>
      <Navigation
        onDayChange={onDayChange}
        onHourChange={onHourChange}
        onMonthChange={onMonthChange}
        onCoalitionChange={onCoalitionChange}
        onAnimationChange={handleAnimationChange}
        onWeekChange={onWeekChange}
        day={day}
        time={time}
        week={week}
        coalition={coalition}
        viewRange={viewRange}
      />
      <Plot
        data={data}
        layout={{
          ...layout,
          yaxis: {
            title: `${nodeMetrics.find(metr => metr.value === nodeMetric).name}`,
            autorange: true
          },
          xaxis: {
            title: viewRange === 'day' ? 'Start hour' : 'Day num.',
            autorange: true,
            dtick: 1
          },
        }}
        useResizeHandler={true}
        style={{ width: "100%", minHeight: "calc(100vh - 220px)" }}
      />
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress color="secondary" size={80} thickness={4} />
          <h1>Network data is loading... Please wait :)</h1>
        </div>
      )}
    </React.Fragment>
  );
};

export default Network;
