import React from "react";

import station from "./station-sample.png";
import path from "./path-sample.png";
import trendUp from './trend-up.png'
import trendDown from './trend-down.png'
import scale from './scale.png'

export const steps = [
  {
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Hi, welcome to the quick guide on how to use this website. Please follow my lead to find out where are the most important parts of the website.
        `}
        ,
        <br />
      </div>
    ),
  },
  {
    selector: "#navigation",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Before we start to use the network let me show you the navigation.
        `}
      </div>
    ),
  },
  {
    selector: "#month-select",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        First, we have the month selector. It allows you to chose which month the data should come from. The default one is October and each change requires downloading that month's data from the server.
        `}
      </div>
    ),
  },
  {
    selector: "#day-slider",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        After selecting the month it's time to select a day of this month. Default day selected is always the 1st of the month but you can choose whatever you wish.
        `}
      </div>
    ),
  },
  {
    selector: "#coalition-select",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        If you want to filter data by specific coalition you can select it here
        `}
      </div>
    ),
  },
  {
    selector: "#date-info",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Here you can check what is exactly the time period you're looking at.
        `}
      </div>
    ),
  },
  {
    selector: ".plot-container",
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        This is the main working place, a place where the magic happens.`}
        <br />
        <br />
        {`
        Each line displays a value for a party. Color of that line corresponds to party's original color.
        `}
        <br />
      </div>
    ),
    style: {
      maxWidth: 450
    }
  },
  {
    selector: "#menu-toggle",
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Now let's check what are the different option you can use. Please click on the ">" arrow and then proceed to the next step.
        `}
      </div>
    ),
  },
  {
    selector: "#left-menu",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Here we have all settings for the chart.
        `}
      </div>
    ),
  },
  {
    selector: "#nodeMetric",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        You're able to select one of many metrics, some of the metrics are cumulative over selected period of time. Other are average (like "Sentiment" for selected period).
        `}
        <br />
      </div>
    ),
    style: {
      maxWidth: 500,
    }
  },
  {
    selector: "#viewRange",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        If you want a chart to display different time range you're able to select one of three different ranges.
        `}
        <br />
      </div>
    ),
    style: {
      maxWidth: 500,
    }
  },
  {
    selector: "#compareReplies",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        If you want a make a sentiment comparison for specific party please select that party from this list.
        `}
        <br />
      </div>
    ),
    style: {
      maxWidth: 500,
    }
  },
  {
    selector: "#groupCoalitions",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        You're able to group results by coalitions. Please notice tha copulative metrics are added to each other, on the other hand metrics like "Sentiment" are average over number of parties in the coalition. That might cause some problem in visialization because if one party in coalition has positive sentiment and the other has negative then on average, the sentiment is neutral.
        `}
        <br />
      </div>
    ),
    style: {
      maxWidth: 500,
      transform: 'translate(275px,10px)'
    }
  },
  {
    selector: "#guide-icon",
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        That's it :) You can always access the guide by clicking on this icon. Enjoy using the network.
        `}
      </div>
    ),
  },
];
