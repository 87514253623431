export const preprocessPaths = (paths = {}) => {
  return paths.map((row) => ({
    type: "scattermapbox",
    lat: row.lng,
    lon: row.lat,
    mode: "lines",
    line: { width: 4, color: "red" },
    id: row.id,
    text: `Connection between ${row.id}`,
  }));
};

export const nodeMetrics = [
  {
    name: "Tweets",
    value: "tw",
  },
  {
    name: "Likes",
    value: "l",
  },
  {
    name: "Retweets of someone elses tweets",
    value: "rt",
  },
  {
    name: "Retweets",
    value: "rc",
  },
  {
    name: "Replies",
    value: "re",
  },
  {
    name: "Sentiment",
    value: "po",
  },
  {
    name: "Replies Sentiment",
    value: "rpo",
  },
];

export const weeks = [
  {
    label: '1.10-4.10',
    days: [1,2,3,4],
    value: 1
  },
  {
    label: '5.10-11.10',
    days: [5,6,7,8,9,10,11],
    value: 2
  },
  {
    label: '12.10-16.10',
    days: [12,13,14,15,16],
    value: 3
  }
]

export const coalitions = [
  {
    value: 1,
    label: "PIS, Porozumienie, SP",
    parties: ['pisorgpl', 'Porozumienie__', 'SolidarnaPL']
  },
  {
    value: 2,
    label: "PO, .N, Zieloni, iPL",
    parties: ['platforma_org', 'Nowoczesna', 'Zieloni', 'inicjatywaPL']
  },
  {
    value: 3,
    label: "Konfederacja, RN, SP",
    parties: ['KONFEDERACJA_', 'RuchNarodowy', 'Partia_KORWiN']
  },
  {
    value: 4,
    label: "PSL, K'15",
    parties: ['nowePSL', 'KUKIZ15']
  },
  {
    value: 5,
    label: "Lewica, Razem",
    parties: ['__Lewica', 'partiarazem']
  }
]

export const parties = {
  pisorgpl: {
    name: 'pisorgpl',
    label: 'PIS',
    color: '#073A76',
    altColor: '#973A76',
    lineType: 'solid',
    coalition: coalitions[0].value
  },
  Porozumienie__: {
    name: 'Porozumienie__',
    label: 'Porozumienie',
    color: '#2B2A6E',
    altColor: '#973A76',
    lineType: 'dashdot',
    coalition: coalitions[0].value
  },
  SolidarnaPL: {
    name: 'SolidarnaPL',
    label: 'Solidarna Polska',
    color: '#002156',
    altColor: '#973A76',
    lineType: 'dot',
    coalition: coalitions[0].value
  },
  platforma_org: {
    name: 'platforma_org',
    label: 'PO',
    color: '#FCA241',
    altColor: '#973A76',
    lineType: 'solid',
    coalition: coalitions[1].value
  },
  Nowoczesna: {
    name: 'Nowoczesna',
    label: 'Nowoczesna',
    color: '#005CA9',
    altColor: '#973A76',
    lineType: 'dashdot',
    coalition: coalitions[1].value
  },
  Zieloni: {
    name: 'Zieloni',
    label: 'Zieloni',
    color: '#99CC00',
    altColor: '#973A76',
    lineType: 'dot',
    coalition: coalitions[1].value
  },
  inicjatywaPL: {
    name: 'inicjatywaPL',
    label: 'Inicjatywa PL',
    color: '#0070A3',
    altColor: '#973A76',
    lineType: 'dash',
    coalition: coalitions[1].value
  },
  KONFEDERACJA_: {
    name: 'KONFEDERACJA_',
    label: 'KONFEDERACJA',
    color: '#102440',
    altColor: '#973A76',
    lineType: 'solid',
    coalition: coalitions[2].value
  },
  RuchNarodowy: {
    name: 'RuchNarodowy',
    label: 'Ruch Narodowy',
    color: '#800000',
    altColor: '#973A76',
    lineType: 'dashdot',
    coalition: coalitions[2].value
  },
  Partia_KORWiN: {
    name: 'Partia_KORWiN',
    label: 'KORWiN',
    color: '#E8B909',
    altColor: '#973A76',
    lineType: 'dot',
    coalition: coalitions[2].value
  },
  nowePSL: {
    name: 'nowePSL',
    label: 'PSL',
    color: '#1BB100',
    altColor: '#973A76',
    lineType: 'solid',
    coalition: coalitions[3].value
  },
  KUKIZ15: {
    name: 'KUKIZ15',
    label: 'Kukiz 15',
    color: '#000000',
    altColor: '#973A76',
    lineType: 'dashdot',
    coalition: coalitions[3].value
  },
  __Lewica: {
    name: '__Lewica',
    label: 'Lewica',
    color: '#E2001A',
    altColor: '#973A76',
    lineType: 'solid',
    coalition: coalitions[4].value
  },
  partiarazem: {
    name: 'partiarazem',
    label: 'Razem',
    color: '#660033',
    altColor: '#973A76',
    lineType: 'dashdot',
    coalition: coalitions[4].value
  }
}

export const preprocessNodes = (nodes = []) => {
  const mapped = nodes.reduce((acc, item) => {
    return [...acc, ...item.parties.map(party => parties[party])]
  }, []);
  return mapped;
};

export const generateRandomFile = () => {
  return Array(31)
    .fill(1)
    .reduce((acc, value, index) => {
      acc[index + 1] = Array(1440 / 15)
        .fill(0)
        .reduce((dayAcc, dayVal, dayId) => {
          dayAcc[dayId * 15] = Array(Math.floor(Math.random() * 15) + 1)
            .fill(1)
            .map((el, index) => {
              const org = Math.floor(Math.random() * 202)
              const dest = Math.floor(Math.random() * 204)
              return {
                o: org,
                d: org === dest ? dest + 1 : dest,
                c: Math.floor(Math.random() * 30) + 1,
              }
            });
          dayAcc[dayId * 15].sort((a, b) => b.c - a.c)
          return dayAcc;
        }, {});
      return acc;
    }, {});
};

export const fillPathsWithData = (paths = {}, nodes = {}) => {
  return (pathData = {}) => {
    const path = paths[`${pathData["o"]}-${pathData["d"]}`];
    return {
      type: "scattermapbox",
      lat: path == null ? [] : path.lng,
      lon: path == null ? [] : path.lat,
      mode: "lines",
      line: { width: pathData["c"]*2, color: "rgba(255,0,0,0.5)" },
      id: path == null ? [] : path.id,
      hoverinfo: "text",
      hovertext: path == null ? 'unknown' : `<span style="font-size: 18px">"${nodes[pathData["o"]].name}" <b>&#8658;</b> "${nodes[pathData["d"]].name}"</span> <br>Bicycles on path: ${pathData["c"]}`,
    };
  };
};

const scl = [[0, 'rgb(0, 0, 200)'],[0.25,'rgb(0, 25, 255)'],[0.375,'rgb(0, 152, 255)'],[0.5,'rgb(44, 255, 150)'],[0.625,'rgb(151, 255, 0)'],[0.75,'rgb(255, 234, 0)'],[0.875,'rgb(255, 111, 0)'],[1,'rgb(255, 0, 0)']];

export const fillNodesMetricData = (metrics = {}, metricKey = 'tw', usePrev = false, viewRange, week, groupCoalitions, compareReplies, prevMetrics, prevMetricTwo) => {
  return (node = {}) => {
    const x = viewRange === 'week' ? weeks.find(w => w.value === week).days : Object.keys(metrics)
    const filterNodes = (metr) => {
      if(groupCoalitions) {
        return node.parties.includes(metr.p);
      }
      return metr.p === node.name;
    }
    let y = []
    if(viewRange === 'day') {
      y = Object.values(metrics).map(hourData => {
        const currMetric = hourData.filter(filterNodes);
        if(currMetric.length === 0) {
          return 0;
        }
        const value = currMetric.reduce((acc, metric) => (acc + metric[metricKey]), 0)
        return ['po', 'rpo'].includes(metricKey) ? value/currMetric.length : value;
      })
    } else if(viewRange === 'week') {
      y = weeks.find(w => w.value === week).days.map(day => metrics[day]).map(monthData => {
        const currMetric = Object.values(monthData).reduce((acc, day, idx) => {
          const currMetric = day.filter(filterNodes);
          const value = currMetric.reduce((acc, metric) => (acc + metric[metricKey]), 0)
          return acc + (['po', 'rpo'].includes(metricKey) ? value/currMetric.length : value);
        }, 0)
        return ['po', 'rpo'].includes(metricKey) ? currMetric/24 : currMetric;
      })
    } else {
      y = Object.values(metrics).map(monthData => {
        const currMetric = Object.values(monthData).reduce((acc, day) => {
          const currMetric = day.filter(filterNodes);
          const value = currMetric.reduce((acc, metric, idx) => {
            return acc + metric[metricKey]
          }, 0)
          return acc + (['po', 'rpo'].includes(metricKey) ? value/currMetric.length : value);
        }, 0)
        return ['po', 'rpo'].includes(metricKey) ? currMetric/24 : currMetric;
      })
    }
    return {
      ids: node.name,
      mode: 'lines+markers',
      connectgaps: true,
      name: node.label,
      line: {
        color: node.color,
        dash: node.lineType,
        width: 4
      },
      x: x,
      y: y,
    };
  };
};

export const fillCompareMetricData = (metrics = {}, metricKey = 'po', usePrev = false, viewRange, week, groupCoalitions, compareReplies, prevMetrics, prevMetricTwo) => {
  return (node = {}) => {
    const x = viewRange === 'week' ? weeks.find(w => w.value === week).days : Object.keys(metrics)
    let yT = []
    let yR = []
    if(viewRange === 'day') {
      yT = Object.values(metrics).map(hourData => {
        const currMetric = hourData.find(metr => metr.p === node.name);
        if(currMetric == null) {
          return 0;
        }
        return currMetric[metricKey];
      })
      yR = Object.values(metrics).map(hourData => {
        const currMetric = hourData.find(metr => metr.p === node.name);
        if(currMetric == null) {
          return 0;
        }
        return currMetric['rpo'];
      })
    } else if(viewRange === 'week') {
      yT = weeks.find(w => w.value === week).days.map(day => metrics[day]).map(monthData => {
        const currMetric = Object.values(monthData).reduce((acc, day, idx) => {
          return acc + day.find(metr => metr.p === node.name)[metricKey];
        }, 0)/24
        return currMetric;
      })
      yR = weeks.find(w => w.value === week).days.map(day => metrics[day]).map(monthData => {
        const currMetric = Object.values(monthData).reduce((acc, day, idx) => {
          return acc + day.find(metr => metr.p === node.name)['rpo'];
        }, 0)/24
        return currMetric;
      })
    } else {
      yT = Object.values(metrics).map(monthData => {
        const currMetric = Object.values(monthData).reduce((acc, day) => {
          return acc + day.find(metr => metr.p === node.name)[metricKey]
        }, 0)/24
        return currMetric;
      })
      yR = Object.values(metrics).map(monthData => {
        const currMetric = Object.values(monthData).reduce((acc, day) => {
          return acc + day.find(metr => metr.p === node.name)['rpo']
        }, 0)/24
        return currMetric;
      })
    }
    return [
      {
        ids: node.name,
        mode: 'lines+markers',
        connectgaps: true,
        name: node.label + ' - Tweets',
        line: {
          color: node.color,
          dash: node.lineType,
          width: 4
        },
        x: x,
        y: yT,
      },
      {
        ids: node.name,
        mode: 'lines+markers',
        connectgaps: true,
        name: node.label + ' - Replies',
        line: {
          color: node.altColor,
          dash: node.lineType,
          width: 4
        },
        x: x,
        y: yR,
      }
    ]
  };
};
